import { template as template_81047aba85534990a6f0c983a2246d87 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_81047aba85534990a6f0c983a2246d87(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
