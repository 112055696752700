import { template as template_aab7a3abf82b447b8bd2721e47e0193e } from "@ember/template-compiler";
const FKControlMenuContainer = template_aab7a3abf82b447b8bd2721e47e0193e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
