import { template as template_2362645958bc49ad9602646f8855e5d4 } from "@ember/template-compiler";
const FKLabel = template_2362645958bc49ad9602646f8855e5d4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
